<template>
  <div class="">
    <div
      style="
        padding: 5px;
        background-color: #bf0000;
        color: #fff;
        font-size: 15px;
      "
    >
      {{ content_name }}
    </div>
    <div
      style="border: 1px solid #aaa; padding: 5px; background-color: #fff2cc"
    >
      <span
        class="desc"
        ref="content"
        style="white-space: pre-line"
        v-html="content_show"
      ></span>
      <span
        class="desc"
        ref="content"
        style="white-space: pre-line"
        v-if="displayMore"
        v-html="content_hide"
      ></span>
      <a
        href="javascript:;"
        v-show="showButton"
        style="color: #bf0000; font-size: 12px"
        v-text="displayMore ? '收起' : '显示全文'"
        @click="displayMore = !displayMore"
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowMoreContent",
  props: {
    content_name: "",
    content_show: "",
    content_hide: "",
  },
  data() {
    return {
      showButton: false,
      displayMore: false,
    };
  },
  mounted() {
    if (this.content_hide) {
      this.showButton = true;
    }
    //console.log(this.content_show);
  },
  methods: {},
  watch: {},
};
</script>

<style>
.desc {
  /* max-height: 80px; */
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-climp: 4;
  overflow: hidden;
}
</style>
